import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadMpQrcode = _resolveComponent("DownloadMpQrcode")!
  const _component_lx_container = _resolveComponent("lx-container")!

  return (_openBlock(), _createBlock(_component_lx_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_DownloadMpQrcode)
    ]),
    _: 1
  }))
}