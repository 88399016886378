
import { defineComponent, onMounted, reactive } from 'vue';
import { getPromoCodeList } from '../service.config';
import {
  qrcodeWidthObj,
  qrcodeDistanceObj,
  QrcodeWidthType,
} from '../page.config';

export default defineComponent({
  setup() {
    const state = reactive({
      tableData: [],
    });

    const fetchPromoCodeList = async () => {
      const { code, data } = await getPromoCodeList();
      if (code !== 0) return;

      const temp = data.codes.map((item: any) => ({
        ...item,
        qrcode_width: qrcodeWidthObj[item.width as QrcodeWidthType],
        distance: qrcodeDistanceObj[item.width as QrcodeWidthType],
      }));
      state.tableData = reactive(temp);
    };

    const handleDownloadQrcode = (url: string) => {
      window.open(url, '_blank');
    };

    onMounted(() => {
      fetchPromoCodeList();
    });

    return {
      state,
      handleDownloadQrcode,
    };
  },
});
