
import { defineComponent } from 'vue';
import DownloadMpQrcode from './components/DownloadMpQrcode.vue';

export default defineComponent({
  components: {
    DownloadMpQrcode,
  },
  setup() {
    return {};
  },
});
